<template>
    <el-main>
        <div class="header">
            <div class="time">
                <span>实时概况</span>
                <span>更新时间：2021-06-23 16:23:05</span>
                <el-button type="text" @click="update">刷新</el-button>
            </div>
        </div>
        <div class="real-survey">
            <div class="title">实时概况</div>
            <div class="content">
                <div class="real-data">
                    <div class="data-type">
                        <el-image :src="require('@/assets/image/data-fw.png')"></el-image>
                        <span>浏览访问</span>
                    </div>
                    <div class="real-data-num">
                        <div class="item">
                            <p>访客数</p>
                            <p>{{ realTimeOverview.today_user_visit_num }}</p>
                            <p>
                                昨日全天
                                <span>{{ realTimeOverview.yestoday_user_visit_num }}</span>
                            </p>
                            <p>
                                占昨日全天
                                <span>
                                    {{
                                        realTimeOverview.today_user_visit_num > 0 && realTimeOverview.yestoday_user_visit_num >
                                        0
                                        ? ((realTimeOverview.today_user_visit_num / realTimeOverview.yestoday_user_visit_num) *
                                            100).toFixed(2)
                                        : '0.00'
                                    }}%
                                </span>
                            </p>
                        </div>
                        <div class="item">
                            <p>浏览量</p>
                            <p>{{ realTimeOverview.today_visit_num }}</p>
                            <p>
                                昨日全天
                                <span>{{ realTimeOverview.yestoday_visit_num }}</span>
                            </p>
                            <p>
                                占昨日全天
                                <span>
                                    {{
                                        realTimeOverview.today_visit_num > 0 && realTimeOverview.yestoday_visit_num > 0
                                        ? ((realTimeOverview.today_visit_num / realTimeOverview.yestoday_visit_num) *
                                            100).toFixed(2)
                                        : '0.00'
                                    }}%
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="real-data">
                    <div class="data-type">
                        <el-image :src="require('@/assets/image/data-zh.png')"></el-image>
                        <span>成交转化</span>
                    </div>
                    <div class="real-data-num">
                        <div class="item">
                            <p>支付金额(元)</p>
                            <p>{{ realTimeOverview.today_pay_success_num }}</p>
                            <p>
                                昨日全天
                                <span>{{ realTimeOverview.yesterday_pay_success_num }}</span>
                            </p>
                            <p>
                                占昨日全天
                                <span>
                                    {{
                                        realTimeOverview.today_pay_success_num > 0 && realTimeOverview.yesterday_pay_success_num
                                        > 0
                                        ? ((realTimeOverview.today_pay_success_num / realTimeOverview.yesterday_pay_success_num)
                                            * 100).toFixed(2)
                                        : '0.00'
                                    }}%
                                </span>
                            </p>
                        </div>
                        <div class="item">
                            <p>成功退款金额(元)</p>
                            <p>{{ realTimeOverview.today_refund_success_num }}</p>
                            <p>
                                昨日全天
                                <span>{{ realTimeOverview.yesterday_refund_success_num }}</span>
                            </p>
                            <p>
                                占昨日全天
                                <span>
                                    {{
                                        realTimeOverview.today_refund_success_num > 0 &&
                                        realTimeOverview.yesterday_refund_success_num > 0
                                        ? ((realTimeOverview.today_refund_success_num /
                                            realTimeOverview.yesterday_refund_success_num) * 100).toFixed(2)
                                        : '0.00'
                                    }}%
                                </span>
                            </p>
                        </div>
                        <div class="item">
                            <p>营业额(元)</p>
                            <p>{{ realTimeOverview.today_turnover }}</p>
                            <p>
                                昨日全天
                                <span>{{ realTimeOverview.yesterday_turnover }}</span>
                            </p>
                            <p>
                                占昨日全天
                                <span>
                                    {{
                                        realTimeOverview.today_turnover > 0 && realTimeOverview.yesterday_turnover > 0
                                        ? ((realTimeOverview.today_turnover / realTimeOverview.yesterday_turnover) *
                                            100).toFixed(2)
                                        : '0.00'
                                    }}%
                                </span>
                            </p>
                        </div>
                        <div class="item">
                            <p>客单价(元)</p>
                            <p>{{ realTimeOverview.today_uni_price }}</p>
                            <p>
                                昨日全天
                                <span>{{ realTimeOverview.yesterday_uni_price }}</span>
                            </p>
                            <p>
                                占昨日全天
                                <span>
                                    {{
                                        realTimeOverview.today_uni_price > 0 && realTimeOverview.yesterday_uni_price > 0
                                        ? ((realTimeOverview.today_uni_price / realTimeOverview.yesterday_uni_price) *
                                            100).toFixed(2)
                                        : '0.00'
                                    }}%
                                </span>
                            </p>
                        </div>
                        <div class="item">
                            <p>支付订单数</p>
                            <p>{{ realTimeOverview.pay_success_log_today_order_num }}</p>
                            <p>
                                昨日全天
                                <span>{{ realTimeOverview.pay_success_log_yesterday_order_num }}</span>
                            </p>
                            <p>
                                占昨日全天
                                <span>
                                    {{
                                        realTimeOverview.pay_success_log_today_order_num > 0 &&
                                        realTimeOverview.pay_success_log_yesterday_order_num > 0
                                        ? ((realTimeOverview.pay_success_log_today_order_num /
                                            realTimeOverview.pay_success_log_yesterday_order_num) * 100).toFixed(2)
                                        : '0.00'
                                    }}%
                                </span>
                            </p>
                        </div>
                        <div class="item">
                            <p>储值卡销售金额(元)</p>
                            <p>{{ realTimeOverview.today_card_user_open }}</p>
                            <p>
                                昨日全天
                                <span>{{ realTimeOverview.yestoday_card_user_open }}</span>
                            </p>
                            <p>
                                占昨日全天
                                <span>
                                    {{
                                        realTimeOverview.today_card_user_open > 0 && realTimeOverview.yestoday_card_user_open >
                                        0
                                        ? ((realTimeOverview.today_card_user_open / realTimeOverview.yestoday_card_user_open) *
                                            100).toFixed(2)
                                        : '0.00'
                                    }}%
                                </span>
                            </p>
                        </div>
                        <div class="item">
                            <p>储值金额(元)</p>
                            <p>{{ realTimeOverview.today_card_recharge_success }}</p>
                            <p>
                                昨日全天
                                <span>{{ realTimeOverview.yestoday_card_recharge_success }}</span>
                            </p>
                            <p>
                                占昨日全天
                                <span>
                                    {{
                                        realTimeOverview.today_card_recharge_success > 0 &&
                                        realTimeOverview.yestoday_card_recharge_success > 0
                                        ? ((realTimeOverview.today_card_recharge_success /
                                            realTimeOverview.yestoday_card_recharge_success) * 100).toFixed(2)
                                        : '0.00'
                                    }}%
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="real-data">
                    <div class="data-type">
                        <el-image :src="require('@/assets/image/data-kh.png')"></el-image>
                        <span>客户会员</span>
                    </div>
                    <div class="real-data-num">
                        <div class="item">
                            <p>新增会员数</p>
                            <p>{{ realTimeOverview.new_open_num_today }}</p>
                            <p>
                                昨日全天
                                <span>{{ realTimeOverview.new_open_num_yesterday }}</span>
                            </p>
                            <p>
                                占昨日全天
                                <span>
                                    {{
                                        realTimeOverview.new_open_num_today > 0 && realTimeOverview.new_open_num_yesterday > 0
                                        ? ((realTimeOverview.new_open_num_today / realTimeOverview.new_open_num_yesterday) *
                                            100).toFixed(2)
                                        : '0.00'
                                    }}%
                                </span>
                            </p>
                        </div>
                        <div class="item">
                            <p>支付人数</p>
                            <p>{{ realTimeOverview.payment_people_num_today }}</p>
                            <p>
                                昨日全天
                                <span>{{ realTimeOverview.payment_people_num_yesterday }}</span>
                            </p>
                            <p>
                                占昨日全天
                                <span>
                                    {{
                                        realTimeOverview.payment_people_num_today > 0 &&
                                        realTimeOverview.payment_people_num_yesterday > 0
                                        ? ((realTimeOverview.payment_people_num_today /
                                            realTimeOverview.payment_people_num_yesterday) * 100).toFixed(2)
                                        : '0.00'
                                    }}%
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="trend">
            <div class="title">今日趋势</div>
            <el-form>
                <el-form-item label="浏览行为：">
                    <el-radio v-model="data_type" @change="changeTrend" :disabled="type == 0 ? !1 : !0"
                        :label="0">访客数</el-radio>
                    <el-radio v-model="data_type" @change="changeTrend" :label="1">浏览量</el-radio>
                </el-form-item>
                <el-form-item label="成交转化：">
                    <el-radio v-model="data_type" @change="changeTrend" :label="2">支付金额</el-radio>
                    <el-radio v-model="data_type" @change="changeTrend" :disabled="type == 0 ? !1 : !0"
                        :label="3">支付人数</el-radio>
                    <el-radio v-model="data_type" @change="changeTrend" :label="4">支付订单数</el-radio>
                    <el-radio v-model="data_type" @change="changeTrend" :disabled="type == 0 ? !1 : !0"
                        :label="5">客单价</el-radio>
                </el-form-item>
            </el-form>
            <div class="select-chart">
                <el-radio-group v-model="type">
                    <el-radio @change="changeTrend(6)" :label="0">分时段趋势图</el-radio>
                    <el-radio @change="changeTrend(7)" :label="1">时段累计图</el-radio>
                </el-radio-group>
            </div>
            <div id="trend-table"></div>
        </div>
        <div class="rank">
            <div class="title">实时商品榜</div>
            <goodsRank :rankList="rankList"></goodsRank>
        </div>
    </el-main>
</template>

<script>
import * as echarts from 'echarts';
import goodsRank from './components/goodsRank';
import { realAnalysis } from './data';
export default {
    components: {
        goodsRank,
    },
    data () {
        return {
            store_id: 0,
            realTimeOverview: {},
            data_type: 0,
            hour_data_type: 0,
            trendData: realAnalysis.options,
            todayTrend: {},
            type: 0,
            trend_table: null,
        };
    },
    computed: {
        rankList () {
            return this.$store.state.utils.goodsRankList;
        },
    },
    created () {
        this.getRealTimeOverview();
        this.getTodayTrend();
    },
    methods: {
        update () {
            this.data_type = 0;
            this.type = 0;
            this.getRealTimeOverview();
            this.getTodayTrend();
        },
        changeTrend (val) {
            let trendData = this.trendData;
            let todayTrend = this.todayTrend;
            console.log(todayTrend);
            let name = '';
            let data = '';
            if (!this.type) {
                if (val == 6) {
                    this.data_type = 0;
                }
                name = this.data_type == 0 ? '访客数' : this.data_type == 3 ? '支付人数' : '客单价';
                data =
                    this.data_type == 0
                        ? todayTrend.data.today_user_visit_num
                        : this.data_type == 1
                            ? todayTrend.data.today_visit_num
                            : this.data_type == 2
                                ? todayTrend.data.today_pay_success_num_price
                                : this.data_type == 3
                                    ? todayTrend.data.payment_people_num_today
                                    : this.data_type == 4
                                        ? todayTrend.data.today_pay_success_nums
                                        : todayTrend.data.today_uni_price;
            } else {
                if (val == 7) {
                    this.data_type = 1;
                }
                name = this.data_type == 1 ? '浏览量' : this.data_type == 2 ? '支付金额' : '支付订单数';
                data = this.data_type == 1 ? todayTrend.hour_data.today_visit_num : this.data_type == 2 ? todayTrend.hour_data.today_pay_success_num_price : todayTrend.hour_data.today_pay_success_nums;
            }
            trendData.series = {
                data: data,
                type: 'line',
                name: name,
            };
            this.trend_table.setOption(trendData);
        },
        //获取实时概况数据
        getRealTimeOverview () {
            this.$axios.post(this.$api.data.realTimeOverview).then(res => {
                if (res.code == 0) {
                    console.log(res);
                    this.realTimeOverview = res.result;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        //获取今日趋势数据
        getTodayTrend () {
            this.$axios.post(this.$api.data.todayTrend).then(res => {
                if (res.code == 0) {
                    console.log(res);
                    let info = res.result;
                    let trendData = this.trendData;
                    trendData.xAxis.data = info.data.date;
                    trendData.series = {
                        data: info.data.today_user_visit_num,
                        type: 'line',
                        name: '访客数',
                    };
                    let chartDom = document.getElementById('trend-table');
                    this.trend_table = echarts.init(chartDom);
                    this.trend_table.setOption(trendData);
                    this.todayTrend = info;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    color: #333;

    .header {
        background: #fff;
        display: flex;
        align-items: center;
        padding: 0 20px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .time span {
            margin-right: 20px;

            &:nth-child(2) {
                color: #999;
            }
        }
    }

    .real-survey {
        padding: 30px 20px;
        margin-top: 7px;
        background: #fff;

        .title {
            font-size: 20px;
        }

        .content {
            margin-top: 20px;

            .real-data {
                display: flex;
                margin-bottom: 5px;

                .data-type {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 107px;
                    color: #fff;
                    font-weight: 500;

                    .el-image {
                        width: 26px;
                        height: 26px;
                        margin-bottom: 5px;
                    }
                }

                .real-data-num {
                    flex: 1;
                    display: flex;
                    flex-wrap: wrap;

                    .item {
                        padding: 0 30px 0 20px;
                        width: 290px;
                        height: 118px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        &:last-child {
                            margin: 0;
                        }

                        p {
                            font-weight: 500;
                            margin-top: 4px;

                            &:nth-child(1) {
                                font-size: 12px;
                            }

                            &:nth-child(2) {
                                font-size: 18px;
                            }

                            &:nth-child(3),
                            &:nth-child(4) {
                                display: flex;
                                justify-content: space-between;
                                color: #999;

                                span {
                                    color: #333;
                                }
                            }
                        }
                    }
                }

                &:nth-child(1) {
                    background: #ecfbfc;

                    .data-type {
                        background: #0bced9;
                    }
                }

                &:nth-child(2) {
                    background: #f2f2fe;

                    .data-type {
                        background: #5654f3;
                    }
                }

                &:nth-child(3) {
                    background: #fff2f7;

                    .data-type {
                        background: #fe4986;
                    }
                }
            }
        }
    }

    .trend {
        padding: 30px 20px;
        margin-top: 7px;
        background: #fff;

        .title {
            font-size: 20px;
        }

        .el-form {
            margin-top: 10px;

            .el-form-item {
                margin: 0;
            }
        }

        #trend-table {
            height: 300px;
            width: 100%;
        }

        .select-chart {
            text-align: right;
        }
    }

    .rank {
        padding: 30px 20px;
        margin-top: 7px;
        background: #fff;

        .title {
            font-size: 20px;
        }
    }
}</style>
